<template>
    <div id="appH5">
        <router-view />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss">
    html,
    body {
        padding: 0;
        margin: 0;
        font-size: 10px;
    }
    
    .input-item {
        outline: none;
    }
    
    #appH5 {
        width: 100%;
        font-family: Arial;
    }
    
    .h5-container {
        width: 100%;
        min-height: 100vh;
    }
    
    .download-popover {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 0 !important;
        
        .app-link {
            max-width: 100px;
            margin-top: 8px;
            font-size: 14px;
            color: #333333;
            word-break: break-all;
        }
    }
    
    .el-tooltip__popper {
        padding: 16px !important;
    }
    
    .cal-list {
        width: 160px;
        
        .cal-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 13px;
            
            &:not(:last-child) {
                margin-bottom: 8px;
            }
            
            .label {
                max-width: 48%;
                color: #A2A5AE;
                word-wrap: break-word;
            }
            
            .value {
                max-width: 48%;
                color: #FFFFFF;
                word-break: break-all;
            }
        }
    }
    
    @media screen and (max-width: 800px) {
    	.el-popup-parent--hidden {
            padding-right: 0 !important;
        }
        
        .el-select-dropdown__item {
            font-size: 1.3rem !important;
        }
    }
    
    .alert-box-h5 {
        width: calc(100% - 3.2rem) !important;
        box-sizing: border-box;
        
        .el-message-box__title {
            font-size: 1.6rem;
        }
        
        .el-message-box__message {
            font-size: 1.3rem;
        }
    }
    
    .prompt-box-h5 {
        width: calc(100% - 3.2rem) !important;
        box-sizing: border-box;
        
        .el-message-box__message {
            font-size: 1.3rem;
        }
        
        .el-input__inner {
            height: 4.2rem;
            line-height: 4.2rem;
            padding: 0 1.2rem;
            border: 1px solid #D3D3D3;
            font-size: 1.4rem;
            color: #000000;
            background-color: #FFFFFF;
            
            &:focus {
                border-color: #D3D3D3;
            }
        }
        
        .prompt-confirm-btn {
            border-color: #5466EF !important;
            background-color: #5466EF !important;
            
            &:hover {
                border-color: rgba(84,102,239,.9) !important;
                background-color: rgba(84,102,239,.9) !important;
            }
        }
        
        .prompt-cancel-btn {
            
            &:hover {
                color: #5466EF;
                border-color: #5466EF;
                background-color: #F3F5FF;
            }
        }
    }
    
    .close-dialog-h5 {
        
        .el-dialog__close:hover {
            color: #5466EF !important;
        }
        
        .el-dialog__title {
            font-size: 1.6rem;
        }
        
        .el-dialog__body {
            padding: 20px 15px 25px !important;
        }
        
        .el-dialog__footer {
            padding: 5px 15px 25px !important;
        }
        
        .input-box {
            display: flex;
            align-items: center;
            width: 100%;
            height: 4.2rem;
            line-height: 4.2rem;
            box-sizing: border-box;
            padding: 0 1.2rem;
            border: 1px solid #D3D3D3;
            border-radius: 4px;
            background-color: #FFFFFF;
            
            .el-input__inner {
                width: 100%;
                height: 3.9rem;
                line-height: 3.9rem;
                padding: 0;
                border: 0;
                font-size: 1.3rem;
                color: #000000;
            }
            
            .all {
                margin-left: 1.2rem;
                font-size: 1.3rem;
                color: #5466EF;
                white-space: nowrap;
                cursor: pointer;
            }
        }
        
        .el-button {
            width: 100%;
            padding: 1.6rem;
            font-size: 1.4rem;
            border-color: #5466EF;
            background-color: #5466EF;
            
            &:hover {
                border-color: rgba(84,102,239,.9);
                background-color: rgba(84,102,239,.9);
            }
        }
    }
</style>
