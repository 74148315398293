export default {
    "Module": {
        "Home": "ホームページ",
        "PerpetualContract": "無期限契約",
        "CurrencyTrading": "通貨取引",
        "SecondContract": "二度目の契約",
        "Finance": "財務管理",
        "Announcement": "発表",
        "Assets": "資産",
        "Account": "アカウント"
    },
    "Login": {
        "Login": "ログイン",
        "Register": "登録",
        "RetrievePassword": "パスワードを取得する",
        "Type": "Eメール",
        "Email": "Eメール",
        "Password": "パスワード",
        "ConfirmPassword": "パスワードを認証する",
        "VerificationCode": "検証コード",
        "Send": "送信",
        "PhoneNumber": "電話番号",
        "InvitationCode": "招待コード",
        "Agree": "私は読み、同意します",
        "UserAgreement": "ユーザー規約",
        "EnterEmail": "あなたのメールアドレスを入力してください",
        "EnterEmailError": "正しいメール形式を入力してください",
        "EnterPassword": "パスワードを入力してください",
        "EnterPasswordError": "パスワードの形式は 6 ～ 20 文字です",
        "EnterConfirmPassword": "パスワードを確認してください",
        "EnterConfirmPasswordError": "2 回入力したパスワードが一致しません",
        "EnterEmailFirst": "最初にメールアドレスを入力してください",
        "EnterVerificationCode": "確認コードを入力してください",
        "EnterPhoneNumber": "携帯電話番号を入力してください",
        "EnterInvitationCode": "招待コードを入力してください",
        "ConfirmAgreement": "利用規約を読んで同意してください",
        "Forget": "パスワードをお忘れですか？"
    },
    "Home": {
        "TcTitle": "通知",
        "TopTitle1": "暗号通貨への投資をすぐに開始",
        "TopTitle2": "Trust Purse は、暗号通貨を売買するための最も信頼できる安全な場所です",
        "EmailAddress": "電子メールアドレス",
        "StartUsing": "使い始める",
        "SeeMore": "続きを見る",
        "MarketTrend": "市場動向",
        "Currency": "通貨",
        "LatestPrice": "最新の価格",
        "Change": "24時間変更",
        "TradingVolume": "24時間取引量",
        "Trend": "傾向",
        "Operate": "操作する",
        "Trade": "トレード",
        "AllTradingVolume": "取引所の24時間取引量",
        "SupportedCountries": "サポートされている国",
        "RegisterNumber": "登録ユーザー数",
        "Box4Title": "最も信頼できる暗号通貨プラットフォーム",
        "Box4Desc": "Trust Purse を選ぶべき理由は次のとおりです。",
        "Box4TabTitles": [
            "多様な取引",
            "最低料金",
            "一流のセキュリティ",
            "究極のユーザー エクスペリエンス"
        ],
        "Box4TabDescs": [
            "Trust Purse は、多様な取引ニーズを満たすために、最高の流動性、豊富な暗号通貨取引、および資産管理サービスを備えたオーダーブックを提供します。",
            "トレーダーにピアツーピア金融市場へのアクセスを提供することで、Trust Purse は取引手数料が最も低い特定の仮想通貨スポット取引ペアを選択します。",
            "Trust Purse はオフライン ストレージ方式を採用し、個人のトランザクション データはエンド ツー エンドの暗号化によって保証され、プラットフォームはリアルタイムで監視してユーザー資産のセキュリティを完全に保護します。",
            "ユーザー第一、使いやすい、フィードバックに耳を傾ける、迅速な製品最適化、7*24 時間のリアルタイム オンライン、多言語の 1 対 1 のプロフェッショナル サービス。"
        ],
        "Box5Title": "さまざまなユーザーのニーズに応える製品ラインを常に充実",
        "Box5TabTitles": [
            "無期限契約",
            "通貨取引",
            "二度目の契約",
            "財務管理"
        ],
        "Box5TabDescs": [
            "レバレッジ取引とは、レバレッジを利用して自己資金を証拠金とし、プラットフォームからN倍の資金を元本として借り入れ、投資を行って利益を得る方法です。",
            "特定のデジタル通貨を価格設定単位として使用し、他の種類のデジタル通貨を購入し、最高の価格で仮想通貨を迅速に売買し、最高のリターンを獲得します。",
            "金融デリバティブは、短期的な市況を分析することにより、トレーダーが特定の時間にあらかじめ決められた価格で売買し、速い頻度で取引して利益を上げます。",
            "競争力のある高い年率収益率、一流の資本保証、簡単に収入を得ることができます!"
        ],
        "TradeNow": "今すぐ取引する",
        "Box6Title1": "いつでもどこでも取引開始",
        "Box6Title2": "APPとWEBの両方で迅速に取引を開始できます",
        "FooterItems1": [
            "規約とポリシー",
            "料金基準",
            "リスク警告",
            "アンチマネーロンダリング",
            "私たちに関しては"
        ],
        "FooterItems2": [
            "サービスのプライバシー",
            "利用規約",
            "プライバシーに関する声明",
            "白書",
            "MSB認証"
        ],
        "FooterItems3": [
            "ヘルプセンター",
            "初心者ガイド",
            "操作ガイド",
            "お問い合わせ"
        ]
    },
    "Trade": {
        "EnterCurrencyName": "通貨名を入力してください",
        "Currency": "通貨",
        "LatestPrice": "最新の価格",
        "Change": "変化",
        "Highest": "最大 24 時間",
        "Lowest": "最低24時間",
        "OrderBook": "注文",
        "LatestRecord": "最新のお得な情報",
        "All": "全て",
        "Bids": "買う",
        "Asks": "売り注文",
        "Price": "価格",
        "Quantity": "量",
        "Total": "合計",
        "Time": "時間",
        "AveragePrice": "平均の値段",
        "EnterPayPass": "取引パスワードを入力してください",
        "BuyTips": "購入してもよろしいですか？",
        "type": "タイプ"
    },
    "PerpetualContract": {
        "OrderType": "注文タイプ",
        "Market": "市場",
        "EnterQuantity": "購入数量を入力してください",
        "HandlingFee": "手数料",
        "AvailableBalance": "利用可能残高",
        "Lever": "レバー",
        "Fee": "料金",
        "OpenLong": "長く開く",
        "OpenShort": "オープンショート",
        "CurrentOrders": "現在の注文",
        "OrderHistory": "注文履歴",
        "Margin": "マージン",
        "CurrentPrice": "現在の価格",
        "OpeningPrice": "始値",
        "UnsettledProfit": "未確定利益",
        "Operate": "操作する",
        "OpenTime": "営業時間",
        "ClosingTime": "閉店時間",
        "ClosingPrice": "終値",
        "SettlementProfit": "決算利益",
        "ClosePosition": "ポジションを閉じる",
        "Edit": "編集",
        "EditOrder": "注文を編集する",
        "EnterCloseQuantity": "数量を入力してください",
        "ConfirmOrder": "注文を確認",
        "TakeProfitPrice": "テイクプロフィット価格",
        "StopPrice": "ストップ価格"
    },
    "CurrencyTrading": {
        "Buy": "買う",
        "Sell": "売る",
        "MyBalance": "私のバランス",
        "AvailablePurchaseAmount": "購入可能金額",
        "ConvertibleAmount": "換算可能額",
        "CurrentPrice": "現在の価格",
        "BuyQuantity": "購入数量",
        "EnterBuyQuantity": "購入する数量を入力してください",
        "SellQuantity": "販売数量",
        "EnterSellQuantity": "販売する数量を入力してください",
        "RequiredAmount": "必要量",
        "ExchangeAmount": "交換金額",
        "OrderHistory": "注文履歴",
        "Status": "州",
        "Success": "成功"
    },
    "SecondContract": {
        "Buy": "買う",
        "OpeningQuantity": "開口量",
        "EnterOpeningQuantity": "通貨金額を入力してください",
        "SelectQuantity": "量を選んでください",
        "OpeningTime": "開始時間",
        "minute": "分",
        "Balance": "バランス",
        "Transfer": "移行",
        "HandlingFee": "手数料",
        "Profitability": "収益性",
        "BuyUp": "買い占めます",
        "BuyDown": "短く買う",
        "InTransaction": "取引中",
        "Closed": "閉まっている",
        "OrderNumber": "注文番号",
        "CurrentPrice": "現在の価格",
        "Direction": "方向",
        "EstimatedProfitLoss": "推定損益",
        "Countdown": "秒読み",
        "OpeningPrice": "始値",
        "ClosingPrice": "終値",
        "ProfitAndLoss": "利益と損失",
        "MinPurchaseAmount": "最低購入金額"
    },
    "Finance": {
        "TopTitle": "お金の宝物",
        "TopDesc": "金利を獲得するための通貨を保持するブロックチェーン資産であり、固定（ロックされた財務管理）、現在の財務管理、およびその他のタイプの製品を含む安定した収入の投資プラットフォームであり、安定した投資ユーザーに最適な投資チャネルを提供します",
        "AllProducts": "すべての製品",
        "InProgress": "進行中",
        "AboutToStart": "開始しようとしています",
        "ItsOver": "終わりました",
        "FinancialRecords": "財務記録",
        "ReferenceAnnualized": "参照年換算",
        "day": "空",
        "hour": "時間",
        "minute": "分",
        "second": "2番",
        "Unlock": "ロックを解除",
        "RemainingPosition": "残りの位置",
        "LockedPositions": "ロック位置",
        "EndOfDistance": "距離の終わり",
        "JoinNow": "今すぐ参加",
        "OurAdvantage": "私たちの利点",
        "AdvanListTitles": [
            "効率的な収入",
            "より短いサイクル",
            "安全で信頼できる"
        ],
        "AdvanListDescs": [
            "さまざまな専門的な金融ツールの組み合わせを使用して、ユーザー向けの高品質で利回りの高い金融商品を作成します",
            "サイクルはより柔軟で、ロックアップは 7 日間と短い",
            "プロフェッショナルな管理とリスク管理ソリューションにより、資産のリターンを保護"
        ],
        "JoinNum": "{joinNum} 人が参加しました",
        "DailyRate": "日歩",
        "StartingTime": "開始時間",
        "LockPeriod": "ロックアップ期間",
        "RebateMethod": "リベート方法",
        "RebateMethod1": "満期収益",
        "RebateMethod2": "毎日のリベート",
        "LockPosition": "ロック位置",
        "ProductSpecification": "製品仕様書",
        "LockedCurrency": "ロックされた通貨",
        "SubscriptionAmount": "購読金額",
        "MinAndMax": "最小 最大",
        "TotalPosition": "合計掲載順位",
        "TradingRules": "取引ルール",
        "BuyProductTips": "この製品を購読してもよろしいですか？",
        "Pending": "保留中",
        "Completed": "完了",
        "ProductName": "商品名",
        "PurchaseAmount": "購入金額",
        "CumulativeIncome": "累積収入",
        "EndTime": "終了時間"
    },
    "Assets": {
        "FinancialRecords": "財務記録",
        "Deposit": "デポジット",
        "Withdraw": "撤退",
        "Transfer": "移行",
        "TotalBalance": "総合収支",
        "Refresh": "リフレッシュする",
        "Currency": "通貨",
        "AvailableBalance": "利用可能残高",
        "Frozen": "凍った",
        "Operate": "操作する",
        "Type": "タイプ",
        "Time": "時間",
        "Quantity": "量",
        "SelectCurrency": "通貨を選択",
        "Address": "住所",
        "Notice": "知らせ",
        "DepositRecord": "預金記録",
        "OrderNumber": "注文番号",
        "Status": "州",
        "Status1": "進行中",
        "Status2": "完了",
        "Status3": "失敗",
        "Status4": "異常な",
        "Status5": "キャンセル",
        "WithdrawalAmount": "出金金額",
        "Least": "一番小さい",
        "All": "全て",
        "WithdrawalCurrency": "出金通貨",
        "WithdrawalAddress": "出金先住所",
        "HandlingFee": "手数料",
        "ReceiptAmount": "入金額",
        "TransactionPassword": "取引パスワード",
        "SetTransactionPassword": "取引パスワードの設定",
        "GoogleVerificationCode": "Google 確認コード",
        "EnterWithdrawalAmount": "出金金額を入力してください",
        "EnterWithdrawalAddress": "出金先住所を入力してください",
        "EnterTransactionPassword": "取引パスワードを入力してください",
        "EnterGoogleVerificationCode": "Google 確認コードを入力してください",
        "WithdrawalsRecord": "出金記録",
        "ExchangeCurrency": "通貨両替",
        "ExchangeAmount": "交換金額",
        "ExchangeRate": "為替レート",
        "Expected": "期待される",
        "TransferRecord": "譲渡記録",
        "CurrencyTransfer": "送金通貨",
        "Success": "成功",
        "EnterExchangeAmount": "両替金額を入力してください"
    },
    "Account": {
        "Logout": "サインアウト",
        "PersonalCenter": "パーソナルセンター",
        "LoginHistory": "ログイン履歴",
        "Time": "時間",
        "Account": "アカウント",
        "LoginPassword": "ログインパスワード",
        "Modify": "改訂",
        "TransactionPassword": "取引パスワード",
        "SetTransactionPassword": "取引パスワードの設定",
        "ResetTransactionPassword": "取引パスワードのリセット",
        "Recommend": "お勧め",
        "MobileAuth": "モバイル認証",
        "BindMailbox": "メールボックスをバインド",
        "GoogleAuth": "グーグル認証",
        "KYCCertification": "KYC認証",
        "MobileAuthDesc": "パスワードを取得し、セキュリティ検証のためにセキュリティ設定を変更するために使用されます",
        "BindMailboxDesc": "パスワードを取得し、セキュリティ検証のためにセキュリティ設定を変更するために使用されます",
        "GoogleAuthDesc": "セキュリティ検証のための通貨の引き出しに使用",
        "Add": "追加",
        "Remove": "削除",
        "GoAuth": "認証に行く",
        "Verified": "検証済み",
        "UnderReview": "審査中",
        "ChangeLoginPassword": "ログインパスワードの変更",
        "ChangeLoginPasswordTips": "ログインパスワード変更後24時間以内は出金できませんのでご注意ください",
        "OriginalPassword": "元のパスワードを入力してください",
        "NewPassword": "新しいパスワードを入力",
        "ConfirmPassword": "新しいパスワードをもう一度入力してください",
        "EnterOriginalPassword": "元のパスワードを入力してください",
        "EnterNewPassword": "新しいパスワードを入力してください",
        "EnterConfirmPassword": "新しいパスワードをもう一度入力してください",
        "EnterPasswordError": "パスワードの形式は 6 ～ 20 文字です",
        "EnterConfirmPasswordError": "2 回入力したパスワードが一致しません",
        "ChangeTransactionPasswordTips": "取引パスワード変更後24時間以内は出金できませんのでご注意ください",
        "EnterLoginPassword": "ログインパスワードを入力してください",
        "PleaseEnterLoginPassword": "パスワードを入力してください",
        "AddGoogleAuth": "Google 認証システムを追加",
        "Step1": "ステップ 1 「Google Authenticator」アプリをダウンロードします",
        "Step2": "ステップ 2 Google Authenticator にキーを追加してバックアップする",
        "Step3": "ステップ 3 Google Validator からの 6 桁の確認コードを入力します",
        "Step1Desc": "iOS ユーザーは App Store にログインし、「Authenticator」を検索してダウンロードします<br/><br/>Android ユーザーは、App Store にログインするか、モバイル ブラウザーを使用して「Google Authenticator」を検索してダウンロードします。",
        "Step2Desc": "Google 認証システムを開き、下の QR コードをスキャンするか、下のキーを手動で入力して確認トークンを追加します",
        "CopyKey": "コピーキー",
        "EnterGoogleVerificationCode": "Google 確認コードを入力してください",
        "PleaseEnterGoogleVerificationCode": "Google 確認コードを入力してください",
        "RemoveGoogleAuth": "Google バリデーターを削除する",
        "RemoveGoogleAuthTips": "Google Validator を削除してから 24 時間以内に現金を引き出すことはできません。慎重に操作してください",
        "UploadIDPhoto": "証明写真のアップロード",
        "UploadIDPhotoTips": "ドキュメント全体のカラー画像をアップロードします。スクリーンショットは許可されていません。画像のサイズは 10M を超えることはできず、JPG または PNG 形式のみを使用できます。",
        "FrontPhoto": "身分証明書の正面写真",
        "BackPhoto": "IDカードの裏面の写真",
        "FrontPhotoTips": "クリックして身分証明書の正面写真をアップロード",
        "BackPhotoTips": "クリックして、ID カードの裏面の写真をアップロードしてください"
    },
    "FundingType": {
        "All": "全て",
        "system": "システム操作",
        "commission": "手数料",
        "recharge": "再充電",
        "withdraw": "撤退",
        "withdrawFail": "出金に失敗しました",
        "second_order": "数秒で注文",
        "secondOrderWin": "2度目の契約獲得",
        "perpetual_order": "無期限契約注文保証金＋手数料",
        "perpeTualOrderClo": "無期限契約のクロージング",
        "trade": "通貨取引",
        "buyFinance": "お金を買う",
        "financeProfit": "金融リベート",
        "financeMoney": "返金元本"
    },
    "Common": {
        "Language": "言語",
        "Tips": "ヒント",
        "Confirm": "確認",
        "Cancel": "キャンセル",
        "Submit": "送信",
        "copy": "コピー",
        "CopySuccessfully": "正常にコピー",
        "NoData": "データなし",
        "Loading": "待っている...",
        "Back": "戻る",
        "Details": "詳細"
    }
}
